* {
  box-sizing: border-box;
}

.grid {
  margin: 0 auto;
  /* Center the grid */
  padding-bottom: 50px;
  /* Add bottom padding to push content up above the footer */
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

.grid-sizer {
  width: 33.333%;
}

/* Masonry item styles for images */
.grid-item {
  width: 33.333%;
  margin-bottom: 0px;
  padding: 0px; /* Padding around the item for spacing */
  box-sizing: border-box; /* Include padding in the width of the element */
  /* Remove bottom margin */
}

.grid-item, .grid-item img {
  margin: 0;
}

.grid-item img {
  width: 100%;
  /* Full width of the masonry block */
  height: auto;
  /* Maintain aspect ratio */
  display: block;
  /* Remove any inline spacing */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above everything else */
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: white;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}