/* Reset default margins and padding for a full-bleed effect */
body, html {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

/* Ensures all elements consider border and padding for total width and height */
*, *::before, *::after {
  box-sizing: inherit;
}

/* Remove default text-align center from App class as it might conflict with the gallery layout */
.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  /* padding: 10px; */
  display: flex;
  flex-direction: row; /* Align header items horizontally */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Space out the items */
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-logo {
  height: calc(32px + 2vmin); /* Adjust the logo size */
  /* margin-bottom: 1rem; */
  margin-right: 0.5rem;
}

.App-name {
  color: inherit; /* This makes the color the same as the parent element */
  text-decoration: none; /* Removes the underline */
  transition: transform 0.3s ease;
  /* margin-bottom: 1rem; */
}

.App-name:hover {
  transform: scale(1.1);
  text-decoration: none; /* Ensures the underline doesn't reappear on hover */
}

.App-logo, .App-name {
  transition: transform 0.3s ease;
}

.App-logo:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

h1 {
  font-size: 2rem; 
  /* margin-bottom: 0.5rem; */
}

/* Main content styling */
main {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align sections to the start of the main axis */
  width: 96.65%; /* Use the full width */
  align-items: center;
}

 Section styles 
 section {
   margin: 1rem 0; 
   padding: 1rem; 
   border: 1px solid #ccc; 
   box-shadow: 0 4px 6px rgba(0,0,0,0.1); 
 } 

.gallery-links {
  text-align: center;
  margin-bottom: 1rem;
}

.gallery-link {
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  font-family: 'Arial', sans-serif; /* Simple, clean font */
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  outline: none; /* Remove outline to keep the design clean */
  color: #333; /* Neutral text color */
}

.gallery-link:hover,
.gallery-link:focus,
.gallery-link:active {
  border-color: #333; /* Darker border on hover/active */
  color: white; /* Change text color for contrast */
  background-color: #333; /* Darker background on hover/active */
}

/* Highlight the currently selected category */
.gallery-link.active {
  border-color: #333;
  color: white;
  background-color: #333;
}

/* Full-bleed gallery style */
.gallery {
  padding: 0; /* Remove padding to allow images to reach the edge */
  margin: 0; /* Remove margin to allow images to reach the edge */
  width: 100vw; /* Set gallery width to the full width of the viewport */
  overflow: hidden; /* Prevents horizontal scrollbar if there's slight overflow */
  margin-bottom: 50px; /* Add margin to the bottom of the gallery */
}

/* Style for the about sections */
.about {
  align-self: stretch; /* Stretch to fill the width of the main container */
  text-align: center;
  /* padding: 10px; */
  /* margin: 10px 0; */
  width: 100%; /* Full width */
}

/* Style for the contact sections */
.contact {
  align-self: stretch;
  text-align: left;
  /* padding: 10px; */
  /* margin: 10px 0; */
  width: 100%; /* Full width */
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin-bottom: 1rem; */
}

.contact-info a {
  margin: 0 10px;
  display: inline-block;
}

.contact-info a img {
  height: 25px;
  width: auto;
  transition: transform 0.3s ease;
}

.contact-info a img:hover {
  transform: scale(1.2); /* Slight zoom effect on hover */
}

.App-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10; /* Make sure this is higher than the z-index of gallery items if they have one */
}

/* Larger screen styles */
@media (min-width: 768px) {
  .App-header {
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
  }

  .App-logo {
    height: calc(64px + 2vmin);
    margin-right: 1rem; /* Space after logo on larger screens */
    margin-bottom: 0; /* Reset margin-bottom for larger screens */
    margin-top: 5px;
    margin-left: 5px;
  }

  .App-name {
    margin-right: 1rem; /* Space after title on larger screens */
    margin-bottom: 0; /* Reset margin-bottom for larger screens */
  }

  main {
    flex-direction: row;
    align-items: flex-start;
    width: 100%; /* Use the full width */
    padding-bottom: 50px;
  }
  
  .contact {
    width: auto; /* Allow these sections to shrink/grow as needed */
    margin: 0; /* Reset margin for larger screens */
    padding: 1rem;
  }
  
  .contact-info {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0; /* Reset margin-bottom for larger screens */
  }

  /* Style for the about sections */
  .about {
    flex-direction: column;
    align-self: stretch; /* Stretch to fill the width of the main container */
    text-align: center;
    /* padding: 10px; */
    /* margin: 10px 0; */
    width: auto; /* Full width */
    padding: 1rem;
  }

  .gallery-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .gallery-link {
    background-color: transparent;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
    font-family: 'Arial', sans-serif; /* Simple, clean font */
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    outline: none; /* Remove outline to keep the design clean */
    color: #333; /* Neutral text color */
    width: 100%; /* Make buttons full width */
  }
  
  .gallery-link:hover,
  .gallery-link:focus,
  .gallery-link:active {
    border-color: #333; /* Darker border on hover/active */
    color: white; /* Change text color for contrast */
    background-color: #333; /* Darker background on hover/active */
  }
  
  /* Highlight the currently selected category */
  .gallery-link.active {
    border-color: #333;
    color: white;
    background-color: #333;
  }

  .App-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10; /* Make sure this is higher than the z-index of gallery items if they have one */
  }
}